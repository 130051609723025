import React, {useState, useMemo, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {Stack, Box, Chip, Divider, Button, Skeleton} from '@mui/material';
import dayjs from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {Card} from '@/atoms/Card';
import {Dialog} from '@/atoms/Dialog';
import {Headline2} from '@/atoms/Headline2';
import {HeadlineText} from '@/atoms/HeadlineText';
import IconLabel from '@/atoms/IconLabel/IconLabel';
import {IconLabelProps} from '@/atoms/IconLabel/interfaces';
import {IconSVG} from '@/atoms/IconSVG';
import {IconNames} from '@/atoms/IconSVG/interfaces';
import {Link} from '@/atoms/Link';
import {XSText} from '@/atoms/XSText';
import {useScrollPosition} from '@/hooks/useScrollPosition/useScrollPosition';
import {composeAddress} from '@/lib/utils';
import {
  PersonalInformationProps,
  MyAccountQRCodeModalProps,
} from '@/organisms/PersonalInformation/interfaces';
import {useStyles} from '@/organisms/PersonalInformation/styles';
import {CustomerResponse} from '@/store/personas';
import {Colors} from '@/themes/variables';

const BASE_TRANSLATION = 'PersonalInformation';

const PersonalInformation = ({
  customersResponse,
  enableCustomersList = false,
  loading,
  viewAllProfileCB,
  selectedCustomerCb,
  error = false,
}: PersonalInformationProps) => {
  const {deleteScrollPositionById} = useScrollPosition();
  const {t} = useTranslation();
  const styles = useStyles();
  const [selectedCustomer, setSelectedCustomer] = useState<
    CustomerResponse | undefined
  >(undefined);

  useEffect(() => {
    if (selectedCustomerCb) {
      selectedCustomerCb(selectedCustomer);
    }
  }, [selectedCustomer, selectedCustomerCb]);

  useEffect(() => {
    if (!loading && customersResponse.length > 0)
      setSelectedCustomer(customersResponse[0]);
  }, [customersResponse, loading]);

  const getGrantLabelAndIcon = useCallback(
    (active: boolean): IconLabelProps => {
      return {
        icon: active ? 'consent' : 'not_consent',
        label: active
          ? t(`${BASE_TRANSLATION}.grants.granted`)
          : t(`${BASE_TRANSLATION}.grants.notGranted`),
      };
    },
    [t],
  );

  const generateHeaderRows = useMemo(() => {
    if (selectedCustomer === undefined) return [];

    const rows = [
      {
        value: selectedCustomer?.email,
        label: t(`${BASE_TRANSLATION}.email`),
        grantElement:
          selectedCustomer?.emailGrant !== null ? (
            <IconLabel
              {...getGrantLabelAndIcon(selectedCustomer?.emailGrant)}
            />
          ) : null,
      },
      {
        value: t(`${BASE_TRANSLATION}.myAccountGrant.onlineMyAccount`),
        grantElement: (
          <MyAccountGrant
            active={selectedCustomer?.myAccountActive}
            qrcode={selectedCustomer?.myAccountQRCode}
          />
        ),
      },
    ];

    if (!enableCustomersList) {
      rows.splice(0, 0, {
        value: selectedCustomer?.customerId,
        label: t(`${BASE_TRANSLATION}.customerId`),
        grantElement: null,
      });
    }

    return rows;
  }, [enableCustomersList, getGrantLabelAndIcon, selectedCustomer, t]);

  const generateBodyRows = useMemo(() => {
    if (selectedCustomer === undefined) return [];

    return [
      {
        value: selectedCustomer?.mobile,
        label: t(`${BASE_TRANSLATION}.mobile`),
        grantElement:
          selectedCustomer?.mobileGrant !== null ? (
            <IconLabel
              {...getGrantLabelAndIcon(selectedCustomer?.mobileGrant)}
            />
          ) : null,
      },
      {
        value: selectedCustomer?.address
          ? composeAddress(selectedCustomer?.address)
          : null,
        label: t(`${BASE_TRANSLATION}.address`),
        grantElement: null,
        // Keep null as said in task https://luxotticaretail.atlassian.net/browse/CRMAPP-432
        // grantElement:
        //   selectedCustomer?.addressGrant !== null ? (
        //     <IconLabel
        //       {...getGrantLabelAndIcon(selectedCustomer?.addressGrant)}
        //     />
        //   ) : null,
      },
      {
        value: selectedCustomer?.taxCode,
        label: t(`${BASE_TRANSLATION}.taxCode`),
      },
      {
        value: selectedCustomer?.birthDate
          ? dayjs(selectedCustomer?.birthDate)
              .utc()
              .format(dayjs().locale() === 'it' ? 'D MMM YYYY' : 'Do MMMM YYYY')
          : null,
        label: t(`${BASE_TRANSLATION}.dateOfBirth`),
      },
    ];
  }, [getGrantLabelAndIcon, selectedCustomer, t]);

  const printHeader = useMemo(() => {
    if (!selectedCustomer) {
      return <Skeleton height="25px" animation="wave" variant="rounded" />;
    }

    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <HeadlineText superHeavy>{t(`${BASE_TRANSLATION}.title`)}</HeadlineText>
        {!enableCustomersList && (
          <Link
            position="center"
            linkVariant="dark"
            textVariant="body"
            href="#"
            {...(viewAllProfileCB && {
              handleOnClick: () => {
                deleteScrollPositionById('clientResultsContainer');
                viewAllProfileCB();
              },
            })}>
            {t(`${BASE_TRANSLATION}.viewAllProfiles`)}
          </Link>
        )}
      </Stack>
    );
  }, [
    deleteScrollPositionById,
    enableCustomersList,
    selectedCustomer,
    t,
    viewAllProfileCB,
  ]);

  const printCustomersList = useMemo(() => {
    if (!selectedCustomer) {
      return <Skeleton height="75px" animation="wave" variant="rounded" />;
    }

    return (
      <Stack direction="column" gap={0.8}>
        <BodyText>{t(`${BASE_TRANSLATION}.customerListTitle`)}</BodyText>
        <CustomersList
          customersResponse={customersResponse}
          selectedCustomer={selectedCustomer}
          onSelectCustomer={setSelectedCustomer}
        />
      </Stack>
    );
  }, [customersResponse, selectedCustomer, t]);

  const printMainInfo = useMemo(() => {
    if (!selectedCustomer) {
      return <Skeleton height="200px" animation="wave" variant="rounded" />;
    }

    return (
      <Stack
        direction="column"
        gap={1.6}
        divider={<Divider orientation="horizontal" />}
        sx={styles.headerBox}>
        {generateHeaderRows?.map((row, index) => {
          return (
            <CustomerRow
              key={`Header_${selectedCustomer?.customerId}_${index}`}
              value={row?.value}
              label={row?.label}
              grantElement={row?.grantElement}
            />
          );
        })}
      </Stack>
    );
  }, [generateHeaderRows, selectedCustomer, styles.headerBox]);

  const printSecondaryInfo = useMemo(() => {
    if (!selectedCustomer) {
      return <Skeleton height="230px" animation="wave" variant="rounded" />;
    }

    return (
      <Stack
        direction="column"
        gap={1.6}
        divider={<Divider orientation="horizontal" />}>
        {generateBodyRows?.map((row, index) => {
          return (
            <CustomerRow
              key={`Body_${selectedCustomer?.customerId}_${index}`}
              value={row?.value}
              label={row?.label}
              grantElement={row?.grantElement}
            />
          );
        })}
      </Stack>
    );
  }, [generateBodyRows, selectedCustomer]);

  return (
    <Card>
      <Stack direction="column" gap={2.4}>
        {error ? (
          <Stack alignItems={'center'} justifyContent={'center'}>
            <Headline2 medium>{t(`${BASE_TRANSLATION}.error`)}</Headline2>
          </Stack>
        ) : (
          <>
            {printHeader}
            {enableCustomersList && printCustomersList}
            {printMainInfo}
            {printSecondaryInfo}
          </>
        )}
      </Stack>
    </Card>
  );
};

export default React.memo(PersonalInformation);

const CustomersList = React.memo(
  ({
    customersResponse,
    selectedCustomer,
    onSelectCustomer,
  }: {
    customersResponse: PersonalInformationProps['customersResponse'];
    selectedCustomer: CustomerResponse;
    onSelectCustomer: (customer: CustomerResponse) => void;
  }) => {
    const icon = useCallback((customer: CustomerResponse): IconNames => {
      return customer?.channel === 'STORE' ? 'shop' : 'web';
    }, []);

    return (
      <Box
        style={{
          overflowX: 'auto',
          paddingBottom: '16px',
          scrollbarWidth: 'thin',
        }}>
        <Stack direction="row" gap={1.6}>
          {customersResponse?.map(customer => {
            return (
              <Stack
                key={customer?.customerId}
                direction="row"
                gap={0.4}
                alignItems="center">
                <Chip
                  label={customer?.customerId}
                  variant={
                    customer?.customerId === selectedCustomer?.customerId
                      ? 'customerActive'
                      : 'customer'
                  }
                  onClick={() => {
                    onSelectCustomer(customer);
                  }}
                  onDelete={() => {
                    onSelectCustomer(customer);
                  }}
                  deleteIcon={<IconSVG icon={icon(customer)} size={16} />}
                />
              </Stack>
            );
          })}
        </Stack>
      </Box>
    );
  },
);

CustomersList.displayName = 'CustomersList';

const CustomerRow = React.memo(
  ({
    value,
    label,
    grantElement,
  }: {
    value: string | null;
    label?: string;
    grantElement?: JSX.Element | null;
  }) => {
    const gridTemplateColumns = useMemo(() => {
      return !!grantElement ? '1fr max-content' : '1fr';
    }, [grantElement]);

    return (
      <Stack
        display="grid"
        gridTemplateColumns={gridTemplateColumns}
        columnGap={0.8}
        rowGap={0.4}
        alignItems="start">
        <BodyText sx={{wordBreak: 'break-all'}}>{value ?? '-'}</BodyText>
        {!!grantElement && grantElement}
        {!!label && <XSText color={Colors.GreyDark}>{label}</XSText>}
      </Stack>
    );
  },
);

CustomerRow.displayName = 'CustomerRow';

const MyAccountGrant = React.memo(
  ({
    active,
    qrcode,
  }: {
    active: CustomerResponse['myAccountActive'];
    qrcode: CustomerResponse['myAccountQRCode'];
  }) => {
    const {t} = useTranslation();
    const styles = useStyles();
    const [modalOpen, setModalOpen] = useState(false);

    const getGrantLabelAndIcon = useCallback(
      (active: boolean): IconLabelProps => {
        return {
          icon: active ? 'consent' : 'not_consent',
          label: active
            ? t(`${BASE_TRANSLATION}.myAccountGrant.active`)
            : t(`${BASE_TRANSLATION}.myAccountGrant.notActive`),
        };
      },
      [t],
    );

    return useMemo(() => {
      if (active) {
        return <IconLabel {...getGrantLabelAndIcon(true)} />;
      }

      if (qrcode === null) {
        return <IconLabel {...getGrantLabelAndIcon(false)} />;
      }

      return (
        <>
          <Stack
            sx={styles.myAccountGrantContainer}
            onClick={() => setModalOpen(true)}>
            <Stack
              sx={styles.myAccountGrantInnerContainer}
              divider={<Divider orientation="horizontal" flexItem />}>
              <Box className="iconLabel">
                <IconLabel {...getGrantLabelAndIcon(false)} />
              </Box>
              <XSText className="scanForRegistration">
                {t(`${BASE_TRANSLATION}.myAccountGrant.scanForRegistration`)}
              </XSText>
            </Stack>
            <img src={qrcode} alt="QRCode" />
          </Stack>
          {modalOpen && (
            <MyAccountQRCodeModal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              qrCode={qrcode}
            />
          )}
        </>
      );
    }, [
      active,
      getGrantLabelAndIcon,
      modalOpen,
      qrcode,
      styles.myAccountGrantContainer,
      styles.myAccountGrantInnerContainer,
      t,
    ]);
  },
);

MyAccountGrant.displayName = 'MyAccountGrant';

const MyAccountQRCodeModal = React.memo(
  ({qrCode, onClose, ...modalProps}: MyAccountQRCodeModalProps) => {
    const {t} = useTranslation();
    const styles = useStyles();
    return (
      <Dialog
        sx={styles.dialog}
        {...modalProps}
        onClose={onClose}
        closeIcon="close"
        dialogTitle={t(`${BASE_TRANSLATION}.modal.title`)}
        titleUppercase={false}
        dialogContent={
          <Stack direction="column" gap={3.2} width="100%">
            <BodyText sx={{whiteSpace: 'pre-line'}}>
              {t(`${BASE_TRANSLATION}.modal.subTitle`)}
            </BodyText>
            <Stack direction="row" justifyContent="center">
              <img src={qrCode} alt="QRCode" />
            </Stack>
          </Stack>
        }
        dialogActions={
          <Button variant="secondary" onClick={onClose}>
            {t(`${BASE_TRANSLATION}.modal.close`)}
          </Button>
        }
      />
    );
  },
);

MyAccountQRCodeModal.displayName = 'MyAccountQRCodeModal';
