import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Divider, SelectChangeEvent, Stack} from '@mui/material';
import {MobileDatePicker} from '@mui/x-date-pickers';
import {useQuery} from '@tanstack/react-query';
import dayjs from 'dayjs';
import {useDebounce} from 'use-debounce';
import validate from 'validate.js';

import {InputWrapper} from '@/atoms/InputWrapper';
import {Select} from '@/atoms/Select';
import {SelectValueType} from '@/atoms/Select/interfaces';
import {Text} from '@/atoms/Typography/Text';
import {Gender, UserStatus} from '@/definitions/user';
import {TextInput} from '@/molecules/TextInput';
import {DropdownPicker} from '@/molecules/dropdownPicker';
import {SubscribeForm} from '@/organisms/CustomerInfo/SubscribeForm';
import {useStyle} from '@/organisms/CustomerInfo/styles';
import {useCustomerInfo} from '@/store/customersInfo';
import {PatchCostumerInfoBody} from '@/store/customersInfo/interfaces';

import {EditFormProps} from './interfaces';

const mandatoryKeys: {[key in keyof PatchCostumerInfoBody]: boolean} = {
  email: true,
  firstName: true,
  lastName: true,
  gender: false,
  dayAndMonthOfBirth: true,
  yearOfBirth: true,
  communications: false,
  countrySearch: false,
  cardNumberSearch: false,
  bannerSearch: false,
};

const EditForm = ({
  onChange,
  formValidationCB,
  thereAreChanges,
  setThereAreChanges,
  hasYear,
  ...customerInfo
}: EditFormProps) => {
  const {t} = useTranslation();
  const {verifyEmail} = useCustomerInfo();
  const {editForm, select} = useStyle();
  const [dayMonthValid, setDayMonthValid] = useState(false);
  const [currentInfo, setCurrentInfo] = useState<PatchCostumerInfoBody>(() => {
    const {
      brandId,
      country,
      cardNumber,
      firstName,
      lastName,
      gender,
      email,
      birthDate,
      communications,
    } = customerInfo;

    const hasBirthdate = Boolean(birthDate);

    const formattedDayAndMonthOfBirth = hasBirthdate
      ? hasYear
        ? dayjs(customerInfo?.birthDate).utc().format('MM/DD')
        : birthDate
      : null;

    const formattedYearOfBirth = hasBirthdate
      ? hasYear
        ? Number(dayjs(customerInfo?.birthDate).utc().format('YYYY'))
        : null
      : null;

    return {
      firstName,
      lastName,
      gender: gender || undefined,
      email,
      communications,
      bannerSearch: brandId,
      countrySearch: country,
      cardNumberSearch: cardNumber,
      dayAndMonthOfBirth: formattedDayAndMonthOfBirth,
      yearOfBirth: formattedYearOfBirth,
    };
  });

  const genderOptions = [
    {
      label: t('CustomerInfo.gender.title'),
      value: undefined,
    },
    {
      label: t('CustomerInfo.gender.values.F'),
      value: Gender.FEMALE,
    },
    {
      label: t('CustomerInfo.gender.values.M'),
      value: Gender.MALE,
    },
    {
      label: t('CustomerInfo.gender.values.OTHER'),
      value: Gender.OTHER,
    },
  ];

  const [debounceEmail] = useDebounce(currentInfo?.email, 500);

  const [communicationStatusConfirmed, setCommunicationStatusConfirmed] =
    useState<boolean>(true);
  const [emailConfirmed, setEmailConfirmed] = useState<boolean>(true);

  /* Check if email already exists */
  useQuery({
    queryKey: ['email', debounceEmail],
    queryFn: () =>
      verifyEmail({
        countrySearch: currentInfo?.countrySearch,
        bannerSearch: currentInfo?.bannerSearch,
        email: debounceEmail,
      }),
    retry: false,
    onSuccess: res => {
      const isMyCardNumber = res.cardNumber === customerInfo.cardNumber;
      setEmailConfirmed(isMyCardNumber);
    },
    onError: err => {
      console.log('onError', err);
      setEmailConfirmed(() => true);
    },
  });

  const validateField = useCallback(
    (key: 'Name' | 'Surname' | 'Email address', value: string) => {
      const constraints = {
        ...((key === 'Name' || key === 'Surname') && {
          [key]: {
            presence: {
              allowEmpty: false,
              message: 'is a mandatory field, to proceed please fill-in',
            },
            format: {
              pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ ]*$/,
              message: 'format inserted is not valid, please try again',
            },
          },
        }),
        ...(key === 'Email address' && {
          'Email address': {
            presence: {
              allowEmpty: false,
              message: 'is a mandatory field, to proceed please fill-in',
            },
            format: {
              pattern:
                /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
              message: 'format inserted is not valid, please try again',
            },
          },
        }),
      };

      const validation = validate({[key]: value}, constraints);

      if (typeof validation === 'undefined') {
        return undefined;
      }
      console.log('key', key);
      console.log('validation', validation);
      return validation[key][0];
    },
    [],
  );

  const isFormValid = useMemo(() => {
    if (!thereAreChanges) {
      return false;
    }

    const dataFormIsValid = (
      Object.keys(currentInfo) as (keyof PatchCostumerInfoBody)[]
    ).every(key => {
      const isMandatory = mandatoryKeys[key];
      if (!isMandatory) {
        return true;
      }

      const value = currentInfo[key];

      switch (key) {
        case 'email':
          return (
            typeof validateField('Email address', value as string) ===
              'undefined' && emailConfirmed
          );
        case 'firstName':
          return typeof validateField('Name', value as string) === 'undefined';
        case 'lastName':
          return (
            typeof validateField('Surname', value as string) === 'undefined'
          );
        case 'dayAndMonthOfBirth':
          return dayMonthValid;
        case 'yearOfBirth':
          return (
            (Boolean(currentInfo?.dayAndMonthOfBirth) && Boolean(value)) ||
            !Boolean(value)
          );
        default:
          return Boolean(value);
      }
    });

    return dataFormIsValid && communicationStatusConfirmed;
  }, [
    thereAreChanges,
    currentInfo,
    communicationStatusConfirmed,
    validateField,
    emailConfirmed,
    dayMonthValid,
  ]);

  useEffect(() => {
    formValidationCB(isFormValid);
  }, [formValidationCB, isFormValid, setThereAreChanges]);

  useEffect(() => {
    onChange({
      ...currentInfo,
      dayAndMonthOfBirth: currentInfo.dayAndMonthOfBirth,
      yearOfBirth: currentInfo.yearOfBirth,
    });
  }, [currentInfo, onChange]);

  useEffect(() => {
    return () => {
      setThereAreChanges(false);
    };
  }, [setThereAreChanges]);

  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = useCallback(
    e => {
      const key = e.target.name;
      const value = e.target.value;
      setThereAreChanges(true);
      setCurrentInfo(prevState => {
        return {
          ...prevState,
          [key]: value.trim(),
        };
      });
    },
    [setThereAreChanges],
  );

  const handleSelectChange = useCallback(
    (e: SelectChangeEvent<SelectValueType>) => {
      const value = e.target.value;
      setThereAreChanges(true);
      setCurrentInfo(prevState => {
        return {
          ...prevState,
          gender: value! as Gender,
        };
      });
    },
    [setThereAreChanges],
  );

  const handleDatePickerChange = useCallback(
    (key: string, date: number | null | string) => {
      setThereAreChanges(true);
      setCurrentInfo(prevState => {
        return {
          ...prevState,
          [key]: date,
        };
      });
    },
    [setThereAreChanges],
  );

  const handleCheckBox: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      e => {
        setThereAreChanges(true);
        setCurrentInfo(prevState => {
          return {
            ...prevState,
            communications: e.target.checked,
          };
        });
        setCommunicationStatusConfirmed(() => e.target.checked);
      },
      [setThereAreChanges],
    );

  const handleCommunicationStatusConfirm = useCallback(() => {
    setCommunicationStatusConfirmed(() => true);
  }, []);

  console.log('currentInfo', currentInfo);

  return (
    <>
      <Stack
        sx={editForm}
        component="form"
        noValidate
        autoComplete="off"
        spacing={1.5}
        width="100%">
        <TextInput
          {...(Boolean(validateField('Email address', currentInfo?.email)) && {
            error: validateField('Email address', currentInfo?.email),
          })}
          {...(!emailConfirmed && {error: t('CustomerInfo.emailError')})}
          inputProps={{name: 'email'}}
          label={t('CustomerInfo.email')}
          value={currentInfo?.email}
          onChange={e => {
            handleChange(e);
          }}
        />
        <Stack direction="row" spacing={1.5}>
          <TextInput
            error={validateField('Name', currentInfo?.firstName)}
            inputProps={{name: 'firstName'}}
            label={t('CustomerInfo.firstName', {context: 'short'})}
            value={currentInfo?.firstName}
            onChange={handleChange}
          />
          <TextInput
            error={validateField('Surname', currentInfo?.lastName)}
            inputProps={{name: 'lastName'}}
            label={t('CustomerInfo.surname', {context: 'short'})}
            value={currentInfo?.lastName}
            onChange={handleChange}
          />
        </Stack>
        <Stack direction="row" spacing={1.5}>
          <InputWrapper label={t('CustomerInfo.gender.title')}>
            <Select
              sx={select}
              placeholder={t('CustomerInfo.gender.title')}
              options={genderOptions}
              value={currentInfo?.gender}
              onChange={handleSelectChange}
            />
          </InputWrapper>
          <Stack direction="column" whiteSpace="nowrap">
            <Text variant="headline5" marginBottom={1} paddingLeft={0.3} medium>
              {t('CustomerInfo.dayBirthDate')}:
            </Text>
            <DropdownPicker
              initialDate={currentInfo?.dayAndMonthOfBirth}
              onChange={date =>
                handleDatePickerChange(
                  'dayAndMonthOfBirth',
                  date ? dayjs(date).utc().format('MM/DD') : date,
                )
              }
              validCB={valid => setDayMonthValid(valid)}
            />
          </Stack>
          <InputWrapper label={t('CustomerInfo.yearBirthDate')}>
            <MobileDatePicker
              views={['year']}
              value={
                currentInfo?.yearOfBirth
                  ? dayjs().set('year', currentInfo?.yearOfBirth)
                  : null
              }
              disableFuture
              format="YYYY"
              onAccept={date =>
                handleDatePickerChange(
                  'yearOfBirth',
                  date ? Number(dayjs(date).utc().format('YYYY')) : date,
                )
              }
              slotProps={{
                actionBar: {
                  actions: ['clear', 'cancel', 'accept'],
                },
                toolbar: {hidden: true},
              }}
              dayOfWeekFormatter={day => day}
            />
          </InputWrapper>
        </Stack>
        <Stack spacing={1.5}>
          <Text variant="headline5" superHeavy>
            {t('CustomerInfo.subscribeForm.title')}
          </Text>
          <Divider />
          <SubscribeForm
            disableCheck={customerInfo.userStatus === UserStatus.SUSPENDED}
            name="communications"
            isChecked={currentInfo?.communications}
            isDisabled={communicationStatusConfirmed}
            setIsChecked={handleCheckBox}
            onConfirm={handleCommunicationStatusConfirm}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default React.memo(EditForm);
