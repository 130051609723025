import dayjs from 'dayjs';

import {Gender} from '@/definitions/user';
import {LoyaltySubscribeFormInputs} from '@/hooks/useLoyaltySubscribeModal/useLoyaltySubscribeModal';
import {PostCustomerinfoSubscribeBody} from '@/store/customersInfo/interfaces';

export const convertToLoyaltySubscriptionPayload = (
  bannerSearch: string,
  countrySearch: string,
  input: LoyaltySubscribeFormInputs,
): PostCustomerinfoSubscribeBody => {
  return {
    bannerSearch,
    countrySearch,
    email: input?.email,
    firstName: input?.firstName,
    lastName: input?.lastName,
    communications: input?.communications,
    ...(input?.gender && {gender: input?.gender as Gender}),
    ...(input?.yearOfBirth && {yearOfBirth: input?.yearOfBirth as number}),
    ...(typeof input?.monthOfBirth === 'number' &&
      typeof input?.dayOfBirth === 'number' && {
        dayAndMonthOfBirth: dayjs()
          .utc(true)
          .set('month', input?.monthOfBirth as number)
          .set('date', input?.dayOfBirth as number)
          .format('MM/DD'),
      }),
  };
};
